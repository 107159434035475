/* Mixins */
/* Groups animations to only apply them
   When the element is in view.
   Also disables animations on devices featuing reduced motion.
 */
/* Maintains element aspect ratio 
*/
/* Orientation 
   - Target device orientation such as portrait or landscape
*/
/* Retina 
   - Target high resolution / retina devices.
   Useful for displaying high resolution graphics to devices that
   can support them. Retina will also target print as retina is designed
   not to change content but make it sharper.
*/
/* Reduced Motion 
   - Target devices where the user has set their preference for reduced motion.
   Animation mixins & functions already account for reduced motion and block animations.
*/
/* Print
   - Target print mode. Useful for hiding content that
   is not relevant on a printed page. Example a tags could be replaced with the actual urls.
*/
/* Standalone
   - Target standalone mode. Useful for changing content based on if a PWA is installed.
*/
/* Responsive Font based off breakpoints */
/* Image background */
.test-format-content {
  padding-left: 100px;
  line-height: normal; }
  @media only screen and (min-width: 70.85714em) and (max-width: 70.85714em) {
    .test-format-content {
      padding-left: 30px; } }
  @media only screen and (max-width: 70.85714em) {
    .test-format-content {
      padding-left: 30px; } }
  @media only screen and (min-width: 54.85714em) and (max-width: 54.85714em) {
    .test-format-content {
      padding-left: 0px; } }
  @media only screen and (max-width: 54.85714em) {
    .test-format-content {
      padding-left: 0px; } }
  .test-format-content .section__one h1 {
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 45px;
    text-align: left;
    color: #231f20;
    padding-bottom: 40px; }
  .test-format-content .section__one p {
    font-family: 'Open Sans';
    font-weight: normal;
    font-size: 20px;
    padding-bottom: 30px;
    text-align: left;
    color: #231f20; }
  .test-format-content .section__two {
    padding-top: 25px; }
    .test-format-content .section__two h2 {
      font-family: "Open Sans";
      font-weight: bold;
      font-size: 30px;
      text-align: left;
      color: #231f20;
      padding-bottom: 40px; }
    .test-format-content .section__two p {
      font-family: 'Open Sans';
      font-weight: normal;
      font-size: 20px;
      padding-bottom: 30px;
      text-align: left;
      color: #231f20; }
  .test-format-content .section__three {
    padding-top: 25px;
    padding-bottom: 110px; }
    @media only screen and (min-width: 54.85714em) and (max-width: 54.85714em) {
      .test-format-content .section__three {
        padding-bottom: 30px; } }
    @media only screen and (max-width: 54.85714em) {
      .test-format-content .section__three {
        padding-bottom: 30px; } }
    .test-format-content .section__three h2 {
      font-family: "Open Sans";
      font-weight: bold;
      font-size: 30px;
      text-align: left;
      color: #231f20;
      padding-bottom: 40px; }
    .test-format-content .section__three p {
      font-family: 'Open Sans';
      font-weight: normal;
      font-size: 20px;
      padding-bottom: 30px;
      text-align: left;
      color: #231f20; }
  .test-format-content .section__four {
    padding-top: 0px; }
    .test-format-content .section__four h2 {
      font-family: "Open Sans";
      font-weight: bold;
      font-size: 30px;
      text-align: left;
      color: #231f20;
      padding-bottom: 40px;
      line-height: normal; }
    .test-format-content .section__four p {
      font-family: 'Open Sans';
      font-weight: normal;
      font-size: 20px;
      padding-bottom: 30px;
      text-align: left;
      color: #231f20;
      line-height: normal; }
  .test-format-content .section__five {
    padding-top: 55px;
    padding-bottom: 55px;
    margin-left: 45px; }
    .test-format-content .section__five h2 {
      font-family: "Open Sans";
      font-weight: bold;
      font-size: 30px;
      text-align: left;
      color: #231f20;
      padding-bottom: 40px; }
    .test-format-content .section__five p {
      font-family: 'Open Sans';
      font-weight: normal;
      font-size: 20px;
      padding-bottom: 30px;
      text-align: left;
      color: #231f20; }
  .test-format-content .download a {
    display: flex;
    background: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2); }
    .test-format-content .download a h3 {
      font-family: 'Open Sans';
      font-weight: normal;
      font-size: 25px;
      text-align: left;
      padding: 20px;
      color: rgba(0, 0, 0, 0.87);
      line-height: normal; }
      @media only screen and (min-width: 54.85714em) and (max-width: 54.85714em) {
        .test-format-content .download a h3 {
          font-size: 18px; } }
      @media only screen and (max-width: 54.85714em) {
        .test-format-content .download a h3 {
          font-size: 18px; } }
    .test-format-content .download a .download-button {
      float: right;
      color: #003399;
      border: none;
      background: transparent;
      font-family: 'Montserrat';
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 0.02em;
      line-height: 20px;
      padding: 20px; }
    .test-format-content .download a .style-download {
      display: flex;
      overflow: hidden;
      flex-direction: column;
      height: 100%; }
      .test-format-content .download a .style-download__content {
        flex: 1; }
  .test-format-content table.table__style {
    width: 100%;
    margin-top: 65px;
    text-align: left;
    border-collapse: collapse;
    border-radius: 4px 4px 0px 0px;
    background: #fff;
    border: 1px solid #E2E2E3; }
    .test-format-content table.table__style strong {
      font-weight: bold;
      font-size: 20px; }
  .test-format-content table.table__style td, .test-format-content table.table__style th {
    border: 1px solid #E2E2E3;
    padding: 3px 2px;
    font-family: "Open Sans";
    font-weight: normal;
    font-size: 18px;
    text-align: left;
    color: rgba(0, 0, 0, 0.8); }
  .test-format-content table.table__style tbody td {
    font-size: 20px;
    padding: 20px; }
    @media only screen and (min-width: 54.85714em) and (max-width: 54.85714em) {
      .test-format-content table.table__style tbody td {
        padding: 6px; } }
    @media only screen and (max-width: 54.85714em) {
      .test-format-content table.table__style tbody td {
        padding: 6px; } }
  .test-format-content table.table__style td:nth-child(1) {
    border-radius: 4px 4px 0px 0px;
    background: rgba(35, 31, 32, 0.05);
    font-family: "Open Sans";
    font-weight: bold;
    padding: 20px;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    border: 1px solid #E2E2E3;
    color: rgba(0, 0, 0, 0.8); }
    @media only screen and (min-width: 54.85714em) and (max-width: 54.85714em) {
      .test-format-content table.table__style td:nth-child(1) {
        padding: 6px; } }
    @media only screen and (max-width: 54.85714em) {
      .test-format-content table.table__style td:nth-child(1) {
        padding: 6px; } }
  .test-format-content .button-flex {
    display: flex;
    margin: 10px;
    font-family: "Open Sans";
    font-weight: bold;
    text-align: center;
    color: #003399; }
    .test-format-content .button-flex .button.\--basic {
      margin: 10px;
      font-size: 18px;
      line-height: normal; }
      @media only screen and (min-width: 54.85714em) and (max-width: 54.85714em) {
        .test-format-content .button-flex .button.\--basic {
          font-size: 16px; } }
      @media only screen and (max-width: 54.85714em) {
        .test-format-content .button-flex .button.\--basic {
          font-size: 16px; } }
    @media only screen and (min-width: 54.85714em) and (max-width: 54.85714em) {
      .test-format-content .button-flex {
        font-size: 16px;
        display: inline-block; } }
    @media only screen and (max-width: 54.85714em) {
      .test-format-content .button-flex {
        font-size: 16px;
        display: inline-block; } }
