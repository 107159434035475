/* Mixins */
/* Groups animations to only apply them
   When the element is in view.
   Also disables animations on devices featuing reduced motion.
 */
/* Maintains element aspect ratio 
*/
/* Orientation 
   - Target device orientation such as portrait or landscape
*/
/* Retina 
   - Target high resolution / retina devices.
   Useful for displaying high resolution graphics to devices that
   can support them. Retina will also target print as retina is designed
   not to change content but make it sharper.
*/
/* Reduced Motion 
   - Target devices where the user has set their preference for reduced motion.
   Animation mixins & functions already account for reduced motion and block animations.
*/
/* Print
   - Target print mode. Useful for hiding content that
   is not relevant on a printed page. Example a tags could be replaced with the actual urls.
*/
/* Standalone
   - Target standalone mode. Useful for changing content based on if a PWA is installed.
*/
/* Responsive Font based off breakpoints */
/* Image background */
.feature-exams .featured-content .featured-content__image {
  width: 50%; }
  @media only screen and (min-width: 54.85714em) and (max-width: 54.85714em) {
    .feature-exams .featured-content .featured-content__image {
      width: 100%; } }
  @media only screen and (max-width: 54.85714em) {
    .feature-exams .featured-content .featured-content__image {
      width: 100%; } }
  .feature-exams .featured-content .featured-content__image i {
    background-size: contain;
    -webkit-clip-path: unset;
    clip-path: unset;
    clip: unset !important; }

.exam-format-content {
  padding-left: 100px;
  line-height: normal; }
  @media only screen and (min-width: 70.85714em) and (max-width: 70.85714em) {
    .exam-format-content {
      padding-left: 30px; } }
  @media only screen and (max-width: 70.85714em) {
    .exam-format-content {
      padding-left: 30px; } }
  @media only screen and (min-width: 54.85714em) and (max-width: 54.85714em) {
    .exam-format-content {
      padding-left: 0px;
      margin-bottom: 30px; } }
  @media only screen and (max-width: 54.85714em) {
    .exam-format-content {
      padding-left: 0px;
      margin-bottom: 30px; } }
  .exam-format-content .section__one {
    padding-bottom: 10px; }
    .exam-format-content .section__one h1 {
      font-family: 'Open Sans';
      font-weight: bold;
      font-size: 45px;
      text-align: left;
      color: #231f20;
      padding-bottom: 40px; }
    .exam-format-content .section__one p {
      font-family: 'Open Sans';
      font-weight: normal;
      font-size: 20px;
      padding-bottom: 20px;
      text-align: left;
      color: #231f20; }
    .exam-format-content .section__one h3 {
      font-family: 'Open Sans';
      font-weight: bold;
      font-size: 30px;
      line-height: 50px;
      color: #231f20;
      padding-bottom: 15px; }
    .exam-format-content .section__one h2 {
      font-family: 'Open Sans';
      font-weight: bold;
      font-size: 35px;
      line-height: 50px;
      text-align: left;
      color: #231f20;
      padding-bottom: 30px;
      padding-top: 30px; }
    .exam-format-content .section__one img {
      width: 100%; }
  .exam-format-content .button.\--red {
    background-color: #003399;
    border-color: #003399;
    font-family: 'Open Sans';
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    color: #fff; }
