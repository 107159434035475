/* Mixins */
/* Groups animations to only apply them
   When the element is in view.
   Also disables animations on devices featuing reduced motion.
 */
/* Maintains element aspect ratio 
*/
/* Orientation 
   - Target device orientation such as portrait or landscape
*/
/* Retina 
   - Target high resolution / retina devices.
   Useful for displaying high resolution graphics to devices that
   can support them. Retina will also target print as retina is designed
   not to change content but make it sharper.
*/
/* Reduced Motion 
   - Target devices where the user has set their preference for reduced motion.
   Animation mixins & functions already account for reduced motion and block animations.
*/
/* Print
   - Target print mode. Useful for hiding content that
   is not relevant on a printed page. Example a tags could be replaced with the actual urls.
*/
/* Standalone
   - Target standalone mode. Useful for changing content based on if a PWA is installed.
*/
/* Responsive Font based off breakpoints */
/* Image background */
.content-inner {
  margin-left: 100px;
  margin-bottom: 110px;
  line-height: normal; }
  @media only screen and (min-width: 70.85714em) and (max-width: 70.85714em) {
    .content-inner {
      padding-left: 30px;
      margin-left: 0px; } }
  @media only screen and (max-width: 70.85714em) {
    .content-inner {
      padding-left: 30px;
      margin-left: 0px; } }
  @media only screen and (min-width: 54.85714em) and (max-width: 54.85714em) {
    .content-inner {
      padding-left: 0px; } }
  @media only screen and (max-width: 54.85714em) {
    .content-inner {
      padding-left: 0px; } }
  @media only screen and (min-width: 54.85714em) and (max-width: 54.85714em) {
    .content-inner {
      margin-bottom: 30px; } }
  @media only screen and (max-width: 54.85714em) {
    .content-inner {
      margin-bottom: 30px; } }
  .content-inner .profile__section {
    margin-top: 40px; }
  .content-inner .profile-format-content {
    display: flex;
    padding-bottom: 40px; }
    @media only screen and (min-width: 54.85714em) and (max-width: 54.85714em) {
      .content-inner .profile-format-content {
        display: inline-block;
        margin-left: 0px;
        text-align: center; } }
    @media only screen and (max-width: 54.85714em) {
      .content-inner .profile-format-content {
        display: inline-block;
        margin-left: 0px;
        text-align: center; } }
    .content-inner .profile-format-content h1 {
      font-family: "Open Sans";
      font-weight: bold;
      font-size: 35px;
      text-align: left;
      margin-left: 20px;
      color: #231f20; }
      @media only screen and (min-width: 54.85714em) and (max-width: 54.85714em) {
        .content-inner .profile-format-content h1 {
          margin-left: 0px;
          text-align: center;
          padding-top: 15px; } }
      @media only screen and (max-width: 54.85714em) {
        .content-inner .profile-format-content h1 {
          margin-left: 0px;
          text-align: center;
          padding-top: 15px; } }
    .content-inner .profile-format-content p {
      font-family: "Open Sans";
      font-weight: normal;
      font-size: 25px;
      text-align: left;
      color: #231f20;
      margin-left: 20px; }
      @media only screen and (min-width: 54.85714em) and (max-width: 54.85714em) {
        .content-inner .profile-format-content p {
          margin-left: 0px;
          text-align: center; } }
      @media only screen and (max-width: 54.85714em) {
        .content-inner .profile-format-content p {
          margin-left: 0px;
          text-align: center; } }
  .content-inner p {
    font-family: "Open Sans";
    font-weight: normal;
    font-size: 20px;
    text-align: left;
    color: #231f20;
    line-height: normal;
    padding-top: 30px; }
  .content-inner h3 {
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 30px;
    text-align: left;
    color: #231f20;
    line-height: normal; }
