.notice {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .notice__title {
    margin: 0;
    font-weight: bold; }
  .notice__caption {
    margin-top: 20px; }
